/* 
  The tailwind base styles must be wrapped in a layer to avoid conflicts with Mantine styles.
  https://github.com/orgs/mantinedev/discussions/1672#discussioncomment-6428444
*/
@layer {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

:root {
  --colors-primary: #7e27bf;
  --colors-accent: #31ddab;
}

::selection {
  background: var(--colors-primary);
  color: white;
}

html {
  scroll-behavior: smooth;
}

#__next {
  height: 100%;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }

  .v-stack {
    @apply flex flex-col;
  }

  .h-stack {
    @apply flex flex-row;
  }

  .flex-center {
    @apply flex justify-center items-center;
  }
}

@layer components {
  .badge {
    @apply inline-block whitespace-nowrap align-middle px-1.5 py-0.5 uppercase text-xs rounded-md font-medium bg-gray-100 text-gray-800;
  }

  .badge-orange {
    @apply !bg-orange-100 !text-orange-800;
  }

  .badge-red {
    @apply !bg-red-100 !text-red-800;
  }

  .badge-green {
    @apply !bg-green-100 !text-green-800;
  }

  .badge-purple {
    @apply !bg-purple-100 !text-purple-800;
  }

  hr {
    @apply border-gray-100;
  }

  /* buttons */

  .btn-base {
    @apply inline-flex justify-center items-center whitespace-nowrap align-middle rounded-md transition-colors duration-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .btn-primary {
    @apply shadow-sm border-transparent text-white bg-primary active:bg-primary-600 hover:bg-primary-600 focus:ring-primary;
  }

  .btn-secondary {
    @apply border-transparent text-primary-600 bg-primary-100 hover:bg-primary-200;
  }

  .btn-outline {
    @apply border border-gray-300 shadow-sm bg-white text-base text-gray-700 hover:bg-gray-50 focus:ring-primary;
  }

  .btn-ghost {
    @apply border-transparent text-primary bg-transparent hover:bg-primary-50 focus:ring-primary;
  }

  .btn-danger {
    @apply shadow-sm border-transparent bg-red-500 text-white hover:bg-red-600  focus:ring-red-500;
  }

  .btn-xs {
    @apply px-2.5 py-1.5 text-xs;
  }

  .btn-sm {
    @apply px-3 py-2 text-sm leading-4;
  }

  .btn-md {
    @apply px-4 py-2 text-sm;
  }

  .btn-lg {
    @apply px-4 py-2 text-base;
  }

  .btn-xl {
    @apply px-6 py-3 text-base;
  }

  /* icon button */

  .icon-btn-xs {
    @apply w-8 h-8;
  }

  .icon-btn-sm {
    @apply w-9 h-9;
  }

  .icon-btn-md {
    @apply w-10 h-10;
  }

  .icon-btn-lg {
    @apply w-11 h-11;
  }

  .icon-btn-xl {
    @apply w-12 h-12;
  }

  /* text fields */

  .text-input {
    @apply w-full h-12 rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:ring-opacity-50 disabled:opacity-60;
  }

  .textarea {
    @apply w-full rounded-md h-auto;
  }

  .text-input[data-valid='true'] {
    @apply focus:ring-primary focus:border-primary border-gray-300;
  }

  .text-input[data-valid='false'] {
    @apply border-2 border-red-500 placeholder-red-500 focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500;
  }

  .input-error {
    @apply mt-2 text-sm text-red-500;
  }

  /* checkbox */

  .checkbox {
    @apply focus:ring-primary h-4 w-4 text-primary border-2 border-gray-300 rounded cursor-pointer;
  }

  /* selects */

  .select {
    @apply appearance-none w-full h-12 px-3.5 bg-white rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-primary focus:border-primary cursor-pointer;
  }

  /* dialogs */

  .dialog-xs {
    @apply max-w-lg;
  }

  .dialog-sm {
    @apply max-w-xl;
  }

  .dialog-md {
    @apply max-w-2xl;
  }

  .dialog-lg {
    @apply max-w-3xl;
  }

  .dialog-xl {
    @apply max-w-4xl;
  }

  .dialog-full {
    @apply max-w-full;
  }

  /* dialog parts */

  .dialog-header {
    @apply py-5 px-6 text-xl font-medium;
  }

  .dialog-body {
    @apply px-6 pt-4 pb-10;
  }

  .dialog-footer {
    @apply flex w-full bg-gray-50 px-4 py-3 sm:px-6 justify-end;
  }

  /* drawer */

  .drawer-base {
    @apply w-screen bg-white;
  }

  .drawer-xs {
    @apply max-w-xs;
  }

  .drawer-sm {
    @apply max-w-sm;
  }

  .drawer-md {
    @apply max-w-md;
  }

  .drawer-lg {
    @apply max-w-lg;
  }

  .drawer-xl {
    @apply max-w-xl;
  }

  .drawer-2xl {
    @apply max-w-2xl;
  }

  .drawer-full {
    @apply max-w-full;
  }

  /* drawer parts */

  .drawer-header {
    @apply py-4 px-6 text-xl font-medium border-b bg-white;
  }

  .drawer-body {
    @apply p-3 sm:p-4 md:p-6 flex-1 overflow-y-auto min-h-0;
  }

  .drawer-footer {
    @apply flex justify-end p-3 border-t border-gray-200 space-x-2 bg-white;
  }

  /* skeletons */

  .skeleton {
    @apply rounded-md bg-gray-200 animate-pulse;
  }

  /* PMS specific */

  .card {
    @apply bg-white p-4 sm:rounded-lg shadow;
  }

  /* tables */

  .table-h-cell {
    @apply px-5 py-3 text-left text-xs font-semibold text-gray-500 whitespace-nowrap uppercase tracking-wider;
  }

  .table-row td {
    @apply px-6 py-4 whitespace-nowrap text-gray-800;
  }

  .table-cell {
    @apply pl-4 sm:pl-5 py-2 sm:py-3 whitespace-nowrap text-gray-800 last:pr-5;
  }

  /* details card */

  .details-card-content {
    @apply grid w-full grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8;
  }

  .tag {
    @apply inline-flex items-center rounded-md font-medium text-[15px] px-2.5 py-1 bg-blackAlpha-100 cursor-pointer hover:bg-blackAlpha-200;
  }

  /* spinners */

  .spinner {
    @apply inline-block border-2 rounded-full text-gray-500 border-gray-500 animate-spin;
  }

  .spinner-xs {
    @apply w-3 h-3;
  }

  .spinner-sm {
    @apply w-4 h-4;
  }

  .spinner-md {
    @apply w-6 h-6;
  }

  .spinner-lg {
    @apply w-8 h-8;
  }

  .spinner-xl {
    @apply w-12 h-12;
  }
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input {
  background: white;
}

/* lightbox fixes */

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center !important;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center !important;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center !important;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center !important;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center !important;
}
