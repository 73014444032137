@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';

@import '../../../global.css';

a {
  color: transparent;
}

#property-images-preview-container::-webkit-scrollbar {
  display: none;
}

#property-images-preview::-webkit-scrollbar {
  display: none;
}

.image-preview-delete-button {
  display: none !important;
}

.image-preview:hover .image-preview-delete-button {
  display: flex !important;
}

.DateRangePicker__CalendarSelection {
  background-color: #fbc02d !important;
  border: 1px solid #828282 !important;
}

.DateRangePicker__CalendarHighlight--single {
  border: 1px solid #828282 !important;
}

.DateRangePicker__Date {
  color: black !important;
}
