/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
  outline: none;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
  outline: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 14px;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
  width: 38px;
  height: 30px;
  border: 1px solid rgb(228, 231, 231);
  outline: none;
}

.DayPicker-NavButton:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.DayPicker-NavButton--prev {
  margin-right: 42px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPgogICAgICA8cGF0aCBkPSJNMzM2IDI3NUwxMjYgNDg1aDgwNmMxMyAwIDIzIDEwIDIzIDIzcy0xMCAyMy0yMyAyM0gxMjZsMjEwIDIxMGMxMSAxMSAxMSAyMSAwIDMyLTUgNS0xMCA3LTE2IDdzLTExLTItMTYtN0w1NSA1MjRjLTExLTExLTExLTIxIDAtMzJsMjQ5LTI0OWMyMS0yMiA1MyAxMCAzMiAzMnoiLz4KICAgIDwvc3ZnPg==');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPgogICAgICA8cGF0aCBkPSJNNjc0IDI0MmwyNDkgMjUwYzEyIDExIDEyIDIxIDEgMzJMNjk0IDc3M2MtNSA1LTEwIDctMTYgN3MtMTEtMi0xNi03Yy0xMS0xMS0xMS0yMSAwLTMybDIxMC0yMTBINjhjLTEzIDAtMjMtMTAtMjMtMjNzMTAtMjMgMjMtMjNoODA2TDY2MiAyNzVjLTIxLTIyIDExLTU0IDMyLTMzeiIvPgogICAgPC9zdmc+');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 16px;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid rgb(228, 231, 231);
  width: 39px;
  height: 38px;
  outline: none;
  color: rgb(72, 72, 72);
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #ffcf59;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #525252;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
}

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background: rgba(35, 47, 62, 0.8);
  color: white;
}

.DayPicker-Day--selected.DayPicker-Day--outside {
  background: rgba(35, 47, 62, 0.5) !important;
  color: white;
}

.DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(35, 47, 62, 0.2) !important;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* PMS Modifiers */

.DayPicker-Day--outside:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}

/* NEW BOOKING */

.DayPicker-Day--new_booking_start {
  background: rgba(35, 47, 62, 0.8);
  color: white;
}
